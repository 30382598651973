import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.currentWindowWidth = window.innerWidth;
    this.checkMenu = this.checkMenu.bind(this);
    this.checkMenu();

    window.addEventListener("resize", this.checkMenu);
  }

  disconnect() {
    window.removeEventListener("resize", this.checkMenu);
  }

  checkMenu() {
    // Close the dropdown if the window is resized and the menu is opened
    if (window.innerWidth !== this.currentWindowWidth) {
      this.currentWindowWidth = window.innerWidth;
      const moreDropdown = this.element.querySelector(".dropdown.show");
      if (moreDropdown) {
        moreDropdown.classList.remove("show");
        moreDropdown.querySelector(".dropdown-menu").classList.remove("show");
      }
    }
  
    const horizontalMenu = this.element;
    const moreDropdownButton = horizontalMenu.querySelector(".dropdown-toggle");
    const moreDropdown = horizontalMenu.querySelector(".dropdown");
    const menuItems = Array.from(horizontalMenu.querySelectorAll(".btn-nav:not(.dropdown-toggle)"));
    let menuWidth = horizontalMenu.getBoundingClientRect().width;
  
    const moreButtonWidth = moreDropdownButton.getBoundingClientRect().width;
    menuWidth = moreDropdownButton.classList.contains("d-none") ? menuWidth : menuWidth - moreButtonWidth;

    // Check if there's room for moving items back from the dropdown to the menu
    let availableWidth = menuWidth - menuItems.reduce((acc, item) => acc + item.getBoundingClientRect().width, 0);

    const menuItemsInMoreDropdown = Array.from(moreDropdown.querySelectorAll('.dropdown-item'));

    while (availableWidth > 0 && menuItemsInMoreDropdown.length) {
      const firstItemInDropdown = menuItemsInMoreDropdown[0];
      const itemWidth = firstItemInDropdown.getBoundingClientRect().width;
      if (availableWidth - itemWidth >= 0) {
        availableWidth -= itemWidth;
        menuItemsInMoreDropdown.shift();
        firstItemInDropdown.classList.remove("dropdown-item");
        moreDropdown.parentElement.insertBefore(firstItemInDropdown, moreDropdown);
      } else {
        break;
      }
    }

    // Move overflowing items to the dropdown
    let currentMenuWidth = 0;
    menuItems.forEach((menuItem) => {
      currentMenuWidth += menuItem.getBoundingClientRect().width;

      if (currentMenuWidth > menuWidth) {
        menuItem.classList.add("dropdown-item");
        moreDropdown.querySelector(".dropdown-menu").prepend(menuItem);
      }
    });

    // Toggle 'More' button visibility based on dropdown content
    const moreDropdownMenu = moreDropdown.querySelector(".dropdown-menu");
    if (moreDropdownMenu.childNodes.length === 0) {
      moreDropdownButton.classList.add("d-none");
    } else {
      moreDropdownButton.classList.remove("d-none");
    }
  }
}