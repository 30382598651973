// tooltips - init (unless mobile device with touch)
if(isTouchDevice()===false) {
  document.addEventListener("turbo:frame-load", function() {
    initTooltips()
  })

  document.addEventListener("turbo:load", function() {
    initTooltips()
  })
}

var initTooltips = function () {
  $(".tooltip").tooltip("hide");
  $('[data-toggle="tooltip"]').tooltip({
    trigger: 'hover'
  })
};

function isTouchDevice(){
  return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}