import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", this.toggleClass.bind(this));
    });
  }

  toggleClass(event) {
    setTimeout(() => {
      // these need to be scoped going forward
      const checkedCheckboxes = document.querySelectorAll(".form-check-input:checked");
      const disabledButton = document.getElementById("btnContinueDisabled")
      const submitButton = document.getElementById("btnContinue")

      if (checkedCheckboxes.length > 0) {
          submitButton.classList.remove("d-none");
          submitButton.classList.add("d-block");
          disabledButton.classList.add("d-none");
          disabledButton.classList.remove("d-block");
      } else {
          disabledButton.classList.remove("d-none");
          disabledButton.classList.add("d-block");
          submitButton.classList.add("d-none");
          submitButton.classList.remove("d-block");
      }
    }, 0);    
  }
}

