import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["opacityField"]
  changeOpacity(){
    var opacity = this.opacityFieldTarget.value / 100
    var element_id = this.opacityFieldTarget.getAttribute('data-element-id')
    var image = document.getElementById(element_id)
    console.log(opacity)
    console.log(element_id)
    console.log(image)
    image.style.opacity = opacity
  }
};