import { Controller } from "stimulus";

export default class extends Controller {
  reloadFrame(event) {
    const frameId = event.currentTarget.dataset.frame;
    const frame = document.getElementById(frameId);
    // Add a cache-busting parameter to force a reload even if URL is the same
    const url = new URL(frame.src);
    url.searchParams.append('random', Math.random());  
    frame.src = url.href;
  }
}