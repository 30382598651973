// app/javascript/controllers/submit_on_enter_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.textareaTarget.addEventListener("keydown", this.handleKeyPress)
  }

  disconnect() {
    this.textareaTarget.removeEventListener("keydown", this.handleKeyPress)
  }

  handleKeyPress(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      if (this.textareaTarget.value.trim() !== "") {
        this.element.requestSubmit()
      }
    }
  }
}