function is_mobile_width(){
  let window_width = $(window).width()
  let mobile_width = '768' // mobile breakpoint
  if (window_width < mobile_width){return true;};
};

function is_tablet_width(){
  let window_width = $(window).width()
  let tablet_width = '992' // in width for responsive content
  if (window_width <= tablet_width){return true;};
};

function hideSocial(){
  $('#social-share-trigger').removeClass('active');
  $('#social-container').hide();
}

// Toggle Navigation
$(document).on('', function() {

  let left_menu = $('#navbar-left')
  let right_menu = $('#navbar-right')
  let container = $('.container, .container-fluid')
  let documents_container = $('.documents-wrapper')

  function resetRightMenu(){

    if (is_mobile_width()){
      resetMobile();
    } else if (is_tablet_width()){
      resetTablet();
    } else {
      resetDesktop();
    };

    function resetMobile(){
      right_menu.css({'width' : '0px'})
      container.css({'padding-right':'15px'});
    };

    function resetTablet(){
      right_menu.css({'width' : '0px'})
      container.css({'padding-right':'15px'});
    };

    function resetDesktop(){
      right_menu.css({'width' : '0px'})
      container.css({'margin-left':'auto', 'margin-left':'auto','padding-right':'15px'});
    };
  };

  function resetLeftMenu(){

    if (is_mobile_width()){
      resetMobile();
    }else if (is_tablet_width()){
      resetTablet();
    }else{
      resetDesktop();
    };

    function resetMobile(){
      left_menu.animate({'width': '0'});
    };

    function resetTablet(){
      left_menu.animate({'width': '60px'});
    };

    function resetDesktop(){
      container.css({'padding-left': '75px'});
      left_menu.animate({'width': '60px'});
    };
  };

  // Toggle Right Nav
  $('[data-toggle="navbar-right"]').on('click', function() {
    let right_menu_width = right_menu.css('width')
    let right_compact_width = "0px"
    let right_full_width = "270px"
    let right_margin_documents = "20px;"

    $(this).toggleClass('active');
    hideSocial();
    resetLeftMenu();

    if (is_mobile_width()){
      togglemobileNav()
    }else if (is_tablet_width()){
      toggletabletNav();
    }else{
      toggledesktopNav();
    };

    function toggledesktopNav(){
      // no animation due to inability to animate automargins in jquery
      if (right_menu_width == right_compact_width){
        container.css({'margin-left': '-'+right_full_width});
        right_menu.css({'width': right_full_width});
      }else{
        right_menu.css({'width': right_compact_width});
        container.css({'margin-left':'auto'});
      };
    };

    function toggletabletNav(){
      if (right_menu_width == right_compact_width){
        container.animate({'margin-left': '-'+right_full_width});
        right_menu.animate({'width': right_full_width});
      }else{
        container.animate({'margin-left':'0'})
        right_menu.animate({'width': right_compact_width});
      }
    };

    function togglemobileNav(){
      if (right_menu_width == right_compact_width){
        container.animate({'margin-left': '-'+right_full_width});
        right_menu.animate({'width': right_full_width});
      }else{
        container.animate({'margin-left':'0'});
        right_menu.animate({'width': right_compact_width});
      }
    }
  });

  // Set timer for nav hover actions
  let timer

  // Expand Left Nav on hover
  $('#navbar-left').mouseenter(function() {
    clearTimeout(timer);
    timer=setTimeout(function(){
      if(!is_mobile_width()){openNavLeft();};
    }, 300);
  });

  // Close Left Nav on mouse exit (with delay)
  $('#navbar-left').mouseleave(function() {
    clearTimeout(timer);
    timer=setTimeout(function(){
       if(!is_mobile_width() ){closeNavLeft();};
    }, 1200);
  });

  // Toggle Left Nav on click
  $('[data-toggle="navbar-left"]').on('click', function() {
    toggleNavLeft();
    hideSocial();
  });

  function openNavLeft(){
    let full_width = "270px"
    let menu_width = "215px"
    if (is_mobile_width()){
      let compact_width = "0px"
      container.css({'margin-right': '0px', 'margin-left' : '0px'})
      container.animate({'margin-left': full_width});
      left_menu.animate({'width': full_width})
    }else if (is_tablet_width()){
      container.css({'margin-right': '75px', 'margin-left' : '0px'})
      container.animate({'margin-left': menu_width});
      left_menu.animate({'width': full_width})
    }else{
      let compact_width = "60px"
      container.animate({'padding-left': full_width});
      documents_container.css({'margin-left': '20px'});
      left_menu.animate({'width': full_width});
    };

  }

  function closeNavLeft(){
    let left_menu = $('#navbar-left')
    let menu_width =left_menu.css('width')

    if (is_mobile_width()){
      let compact_width = "0px"
      container.animate({'margin-left':'0px'});
      left_menu.animate({'width': compact_width})
    }else if (is_tablet_width()){
      let compact_width = "60px"
      container.animate({'margin-left': '0px'});
      left_menu.animate({'width': compact_width});
    }else{
      let compact_width = "60px"
      container.animate({'padding-left':'75px'});
      documents_container.css({'margin-left': ''});
      left_menu.animate({'width': compact_width});
    };
  };

  function toggleNavLeft(){
    let menu_width = $('#navbar-left').css('width')
    let open_width = "270px"
    resetRightMenu();
    menu_width == open_width ? closeNavLeft() : openNavLeft();
  };

  // Secondary Nav
  $('#trigger-secondary-nav').on('click', function() {
    $('#trigger-secondary-nav').toggleClass('active');
    $('body').toggleClass('secondary-nav');
    $('#publish-nav-secondary').toggle();
  });

});

// Adjust nav elements on window resize
$( window ).resize(function() {

  let window_width = $(window).width()
  let mobile_width = '768' // Mobile breakpoint
  let left_menu = $('#navbar-left')
  let right_menu = $('#navbar-right')
  let container = $('.container, .container-fluid')


  function hideSecondaryNav(){
    $('#trigger-secondary-nav').removeClass('active');
    $('body').removeClass('secondary-nav');
    $('#publish-nav-secondary').hide();
  };

});

// Left Menu: Open left nav to correct section on page load
//$( window ).load(function() {
  //$('#contena-academy #nav-cat-academy').addClass('show');
  //$('#contena-academy #nav-cat-academy .product-dropdown').addClass('show');

  //$('#contena-publish #nav-cat-publish').addClass('show');
  //$('#contena-publish #nav-cat-publish .product-dropdown').addClass('show');
//});
