import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["loader", "content"]

  connect() {
    setTimeout(() => {this.fadeOut(this.loaderTarget)}, 300);
    setTimeout(() => {this.fadeIn(this.contentTarget)}, 500);
  };

  // ** FADE OUT FUNCTION **
  fadeOut(el) {
    el.style.opacity = 1;
    (function fade() {
      if ((el.style.opacity -= .1) < 0) {
        el.style.display = "none";
      } else {
        requestAnimationFrame(fade);
      }
    })();
  };

  // ** FADE IN FUNCTION **
  fadeIn(el) {
    el.style.opacity = 0;
    el.style.display = "block";
    (function fade() {
      var val = parseFloat(el.style.opacity);
      if (!((val += .1) > 1)) {
        el.style.opacity = val;
        requestAnimationFrame(fade);
      }
    })();
  };

};