$(document).on('turbo:load', function() {
  initSurvey();
});

window.initSurvey = function(){

  if (document.body.classList.contains('static') && document.body.classList.contains('quickstart')) {
    
    document.addEventListener('keyup', function(event) {
      let target = event.target;
      if (target.matches('form.short-text.required input[type="text"], form.name input[type="text"], form.email.required input[type="text"], form.long-text.required textarea')) {
        validateText(target.value);
      }
    });

    document.addEventListener('change', function(event) {
      let target = event.target;
      let submitButton = document.querySelector('input[type="submit"]');
      
      if (target.matches('input[type="radio"]') || target.matches('input[type="checkbox"]') || target.matches('select')) {
        submitButton.removeAttribute("disabled");
      }
    });
  }
}

window.validateText = function(val) {
  if (val.length > 5) {
    document.querySelector('.btn-action').removeAttribute("disabled");
  } else {
    document.querySelector('.btn-action').setAttribute("disabled", true);
  }
}





