import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggle"]

  toggleTheme() {
    const aiOptionsId = this.element.dataset.aiOptionsId;
    
    // Check if aiOptionsId is being retrieved correctly
    if (!aiOptionsId) {
      console.error("AiOptions ID not found");
      return;
    }

    fetch(`/ai_options/${aiOptionsId}/toggle_theme`, {
      method: "POST",
      headers: { 
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      const currentThemeElement = document.getElementById("currentTheme");
      if (currentThemeElement) {
        currentThemeElement.className = 
          data.theme === "dark" ? "chat-theme-dark" : "chat-theme-light";
      }
    })
    .catch(error => console.error("Error toggling theme:", error));
  }
}