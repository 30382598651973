import { Controller } from "stimulus"
import moment from 'moment'

export default class extends Controller {
	static targets = ["time"]

  connect(){
    this.localizeServerTime()
  }
	localizeServerTime(){
		const targets = this.timeTargets
		const inputDateFormat = 'YYYY-MM-DD HH:mm:ss z'; // input date format (should be in UTC)
		const outputDateFormat = 'MMM Do, YYYY [at] h:mm A'; // output date format
		const todayFormat = '[Today] [at] LT'
		const yesterdayFormat = '[Yesterday] [at] LT'
		const nextDayFormat = '[Tomorrow] [at] LT'


		targets.forEach((e) => {
			// pass date into moment in UTC format, then convert to local time
      e.textContent = moment.utc(e.textContent, inputDateFormat).local().calendar(null, { sameDay: todayFormat, lastDay: yesterdayFormat, nextDay: nextDayFormat, lastWeek:  outputDateFormat, sameElse: outputDateFormat});
		})
	}
}