import { Controller } from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ['checkboxAll', 'checkbox']

  initialize () {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  connect() {
    if (!this.hasCheckboxAllTarget) return
    this.checkboxAllTarget.addEventListener('change', this.toggle)
    this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.refresh))
    this.refresh()
  }

  disconnect (){
    if (!this.hasCheckboxAllTarget) return

    this.checkboxAllTarget.removeEventListener('change', this.toggle)
    this.checkboxTargets.forEach(checkbox => checkbox.removeEventListener('change', this.refresh))
  }

  toggle(e){
    e.preventDefault()

    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = e.target.checked
      this.triggerInputEvent(checkbox)
    })
    this.toggleBulkForm()
  }

  refresh (){
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
    this.toggleBulkForm()
  }

  toggleBulkForm (){
    if (this.checked.length > 0 ) {
      this.displayBulkForm()
    }else{
      this.hideBulkForm()
    }
  }

  displayBulkForm (){
    var bulkForm = document.getElementById("bulkForm")
    if (bulkForm){
			bulkForm.classList.remove('d-none')
		}
  }

  hideBulkForm (){
    var bulkForm = document.getElementById("bulkForm")
    if (bulkForm){
			bulkForm.classList.add('d-none')
		}
  }

  triggerInputEvent (checkbox){
    const event = new Event('input', { bubbles: false, cancelable: true })

    checkbox.dispatchEvent(event)
  }

  get checked (){
    return this.checkboxTargets.filter(checkbox => checkbox.checked)
  }

  get unchecked (){
    return this.checkboxTargets.filter(checkbox => !checkbox.checked)
  }

  destroy(event) {
    event.preventDefault()

    let data = new FormData()
    let checkedboxes = this.checked
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    Rails.ajax({
      url: "/contact_bulk",
      type: "DELETE",
      data: data,
      success: function() {
        checkedboxes.forEach((checkbox) => document.getElementById("contact_"+checkbox.value).remove())
        $('#bulkDeleteConfirmModal').modal('hide');
      }
    })
  }

  update(event) {
    event.preventDefault()

    let bulkForm = document.querySelector(".contacts-form")
    let data = new FormData(bulkForm)
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))

    Rails.ajax({
      url: "/contact_bulk",
      type: "PATCH",
      data: data,
      success: function() {
        let form = document.getElementById('filterrific_filter')
        Rails.fire(form, "submit")
      }
    })
  }
}