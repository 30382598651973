import rangy from "rangy";
import "rangy/lib/rangy-classapplier";
import * as MediumEditor from "medium-editor"
import MediumInsert from "medium-editor-insert-plugin"
window.MediumInsert = MediumInsert.MediumInsert;

$(document).on('turbo:load', function() {
  miniEditable();
  standardEditable();
  contenaEditable();
});

window.standardEditable = function(){
  rangy.init();
  
  var HighlighterButton = MediumEditor.extensions.button.extend({
    name: 'highlighter',
    tagNames: ['mark'], // nodeName which indicates the button should be 'active' when isAlreadyApplied() is called
    contentDefault: '<span class="fas fa-highlighter"></span>', // default innerHTML of the button
    aria: 'highlighter', // used as both aria-label and title attributes
    action: 'highlighter', // used as the data-action attribute of the button

    init: function () {
      MediumEditor.extensions.button.prototype.init.call(this);
  
      this.classApplier = rangy.createClassApplier('highlighter', {
        elementTagName: 'mark',
        normalize: true,
      });
    },
  
    handleClick: function (event) {
      this.classApplier.toggleSelection();
      this.base.checkContentChanged();
    }

  });

  var CodeButton = MediumEditor.extensions.button.extend({
    name: 'code',
    tagNames: ['code'], // nodeName which indicates the button should be 'active' when isAlreadyApplied() is called
    contentDefault: '<span class="fas fa-code"></span>', // default innerHTML of the button
    aria: 'code', // used as both aria-label and title attributes
    action: 'code', // used as the data-action attribute of the button
  
    init: function () {
      MediumEditor.extensions.button.prototype.init.call(this);
  
      this.classApplier = rangy.createClassApplier('code', {
        elementTagName: 'code',
        normalize: true,
      });
    },
  
    handleClick: function (event) {
      this.classApplier.toggleSelection();
      this.base.checkContentChanged();
    }
  });

  /** Call Medium Editors **/

  var editor = new MediumEditor('.editable', {
    placeholder: {
    text: 'Blank document',
    hideOnClick: false
    },
    paste: {
        forcePlainText: false,
        cleanPastedHTML: true,
        cleanReplacements: [
          /* Alignment */
          [new RegExp(/<center/gi), '<div align="center">'],
          [new RegExp(/<\/center>/gi), '</div>'],
          [new RegExp(/<aside/gi), '<p'],
          [new RegExp(/<\/aside>/gi), '</p>'],

          /* Blockquote */
          [new RegExp(/<q>/gi), '<blockquote>'],
          [new RegExp(/<\/q>/gi), '</blockquote>'],

          /* Bold*/
          [new RegExp(/<em>/gi), '<i>'],
          [new RegExp(/<\/em>/gi), '</i>'],
          [new RegExp(/<strong/gi), '<b'],
          [new RegExp(/<\/strong>/gi), '</b>'],

          /* Headings */
          [new RegExp(/<h[1-3]/gi), '<h2'], // Convert h1 - h2 to heading (h2)
          [new RegExp(/<\/h[1-3]>/gi), '</h2>'],
          [new RegExp(/<h[4-6]/gi), '<p><b'], // Convert h3 - h6 to bold paragraph
          [new RegExp(/<\/h[4-6]>/gi), '</p></b>'],
        ],
        cleanAttrs:['class','id','style','dir','lang','paraid','paraeid','xml:lang'],
        cleanTags:['basefont','center','data','dialog','fieldset','form','frame','iframe','meta','noscript','object','script','section','span']
    },
    toolbar: {
      diffTop: -20,
      buttons: [
        {
          name: 'bold',
          contentDefault: '<span class="fas fa-bold"></span>',
        },
        {
          name: 'italic',
          contentDefault: '<span class="fas fa-italic"></span>',
        },
        {
          name: 'underline',
          contentDefault: '<span class="fas fa-underline"></span>',
        },
        {
          name: 'strikethrough',
          contentDefault: '<span class="fas fa-strikethrough"></span>',
        },  
        {
          name: 'h2',
          contentDefault: '<span class="fas fa-heading"></span>',
        }, 
        {
          name: 'quote',
          contentDefault: '<span class="fas fa-quote-left"></span>',
        },      
        {
          name: 'unorderedlist',
          contentDefault: '<span class="fas fa-list-ul"></span>',
        },
        {
          name: 'orderedlist',
          contentDefault: '<span class="fas fa-list-ol"></span>',
        },
        {
          name: 'anchor',
          contentDefault: '<span class="fas fa-link"></span>',
        },
        {
          name: 'removeFormat',
          contentDefault: '<span class="fas fa-text-slash"></span>',
        }
      ]
    },
    extensions: {
      'highlighter': new HighlighterButton(),
      'code': new CodeButton()
    },
    anchor: {
       /* These are the default options for anchor form,
          if nothing is passed this is what it used */
       customClassOption: null,
       customClassOptionText: 'Button',
       linkValidation: false,
       placeholderText: 'Paste or type a link',
       targetCheckbox: false,
       targetCheckboxText: 'Open in new window'
    },
    keyboardCommands: {
    /* This example includes the default options for keyboardCommands,
       if nothing is passed this is what it used */
      commands: [
          {
              command: 'bold',
              key: 'B',
              meta: true,
              shift: false,
              alt: false
          },
          {
              command: 'italic',
              key: 'I',
              meta: true,
              shift: false,
              alt: false
          },
          {
              command: 'underline',
              key: 'U',
              meta: true,
              shift: false,
              alt: false
          },
          {
              command: 'strikethrough',
              key: 'X',
              meta: true,
              shift: true,
              alt: false
          },
          {
              command: 'append-blockquote',
              key: 'B',
              meta: true,
              shift: true,
              alt: false
          },
          {
              command: 'append-h2',
              key: 'H',
              meta: true,
              shift: true,
              alt: false
          },
          {
              command: 'insertunorderedlist',
              key: 'J',
              meta: true,
              shift: true,
              alt: false
          },
          {
              command: 'insertorderedlist',
              key: 'L',
              meta: true,
              shift: true,
              alt: false
          },
          {
              command: 'createlink',
              key: 'K',
              meta: true,
              shift: false,
              alt: false
          }
      ],
    }
  });
  $(function () {
      var postId = $('.post-form-wrapper').attr("data-post-id")
      $('.editable').mediumInsert({
          editor: editor,
          paste: {
            forcePlainText: false,
            cleanPastedHTML: true,
            cleanReplacements: [
              /* Alignment */
              [new RegExp(/<center/gi), '<div align="center">'],
              [new RegExp(/<\/center>/gi), '</div>'],
              [new RegExp(/<aside/gi), '<p'],
              [new RegExp(/<\/aside>/gi), '</p>'],
    
              /* Blockquote */
              [new RegExp(/<q>/gi), '<blockquote>'],
              [new RegExp(/<\/q>/gi), '</blockquote>'],
    
              /* Bold*/
              [new RegExp(/<em>/gi), '<i>'],
              [new RegExp(/<\/em>/gi), '</i>'],
              [new RegExp(/<strong/gi), '<b'],
              [new RegExp(/<\/strong>/gi), '</b>'],
    
              /* Headings */
              [new RegExp(/<h[1-3]/gi), '<h2'], // Convert h1 - h2 to heading (h2)
              [new RegExp(/<\/h[1-3]>/gi), '</h2>'],
              [new RegExp(/<h[4-6]/gi), '<p><b'], // Convert h3 - h6 to bold paragraph
              [new RegExp(/<\/h[4-6]>/gi), '</p></b>'],
            ],
            cleanAttrs:['class','id','style','dir','lang','paraid','paraeid','xml:lang'],
            cleanTags:['basefont','center','data','dialog','fieldset','form','frame','iframe','meta','noscript','object','script','section','span']
        },
        addons: {
            images: {
              label: '<span class="fas fa-upload medium-upload-icon"></span>',
              captionPlaceholder: 'Image caption (optional)', // (string) Caption placeholder
              deleteScript: '',
              fileUploadOptions: {
                url: `images/upload`,
                type: 'post',
                acceptFileTypes: /(.|\/)(gif|jpe?g|png)$/i,
              }
            },
            embeds: { // (object) Embeds addon configuration
                label: '<span class="fab fa-twitter medium-embed-icon"></span>', // (string) A label for an embeds addon
                placeholder: 'Add link to social media post and press enter', // (string) Placeholder displayed when entering URL to embed
                captions: true, // (boolean) Enable captions
                captionPlaceholder: 'Content caption (optional)', // (string) Caption placeholder
                oembedProxy: 'https://iframe.ly/api/oembed?api_key=afa509950953f241ca464c&iframe=1', // (string/null) URL to oEmbed proxy endpoint, such as Iframely, Embedly or your own. You are welcome to use "http://medium.iframe.ly/api/oembed?iframe=1" for your dev and testing needs, courtesy of Iframely. *Null* will make the plugin use pre-defined set of embed rules without making server calls.
                actions: { // (object) Actions for an optional second toolbar
                    remove: { // (object) Remove action configuration
                        label: '<span class="fa fa-times"></span>', // (string) Label for an action
                        clicked: function ($el) { // (function) Callback function called when an action is selected
                            var $event = $.Event('keydown');

                            $event.which = 8;
                            $(document).trigger($event);
                        }
                    }
                }
              },
            }
      });
  });
}

window.contenaEditable = function(){
  var editor = new MediumEditor('.contenaeditable', {
    toolbar: {
      buttons: [
        {
          name: 'bold',
          contentDefault: '<span class="fas fa-bold"></span>',
        },
        {
          name: 'italic',
          contentDefault: '<span class="fas fa-italic"></span>',
        },
        {
          name: 'underline',
          contentDefault: '<span class="fas fa-underline"></span>',
        },
        {
          name: 'strikethrough',
          contentDefault: '<span class="fas fa-strikethrough"></span>',
        },
        {
          name: 'h2',
          contentDefault: '<span class="fas fa-heading"></span>',
        }, 
        {
          name: 'quote',
          contentDefault: '<span class="fas fa-quote-left"></span>',
        },
        {
          name: 'superscript',
          contentDefault: '<span class="fas fa-megaphone"></span>',
        },
        {
          name: 'anchor',
          contentDefault: '<span class="fas fa-link"></span>',
        },
        {
          name: 'unorderedlist',
          contentDefault: '<span class="fas fa-list-ul"></span>',
        },
        {
          name: 'orderedlist',
          contentDefault: '<span class="fas fa-list-ol"></span>',
        },
        {
          name: 'removeFormat',
          contentDefault: '<span class="fas fa-text-slash"></span>',
        }
      ]
    },
    anchor: {
       /* These are the default options for anchor form,
          if nothing is passed this is what it used */
       customClassOption: null,
       customClassOptionText: 'Button',
       linkValidation: false,
       placeholderText: 'Paste or type a link',
       targetCheckbox: false,
       targetCheckboxText: 'Open in new window'
    }
  });
  $(function () {
    var postId = $('.post-form-wrapper').attr("data-post-id")
    $('.contenaeditable').mediumInsert({
      editor: editor,
      addons: {
        images: {
          label: '<span class="fas fa-upload medium-upload-icon"></span>',
          captionPlaceholder: 'Image caption (optional)', // (string) Caption placeholder
          deleteScript: '',
          fileUploadOptions: {
            url: 'images/upload',
            type: 'post',
            acceptFileTypes: /(.|\/)(gif|jpe?g|png)$/i,
          }
        },
        embeds: { // (object) Embeds addon configuration
            label: '<span class="fab fa-twitter medium-embed-icon"></span>', // (string) A label for an embeds addon
            placeholder: 'Add link to social media post and press enter', // (string) Placeholder displayed when entering URL to embed
            captions: true, // (boolean) Enable captions
            captionPlaceholder: 'Content caption (optional)', // (string) Caption placeholder
            oembedProxy: 'https://iframe.ly/api/oembed?api_key=afa509950953f241ca464c&iframe=1', // (string/null) URL to oEmbed proxy endpoint, such as Iframely, Embedly or your own. You are welcome to use "http://medium.iframe.ly/api/oembed?iframe=1" for your dev and testing needs, courtesy of Iframely. *Null* will make the plugin use pre-defined set of embed rules without making server calls.
            actions: { // (object) Actions for an optional second toolbar
                remove: { // (object) Remove action configuration
                    label: '<span class="fa fa-times"></span>', // (string) Label for an action
                    clicked: function ($el) { // (function) Callback function called when an action is selected
                        var $event = $.Event('keydown');

                        $event.which = 8;
                        $(document).trigger($event);
                          } 
                }
              }
          }
         }
      });
  });
}



window.miniEditable = function(){
    /** Limited Medium Editor Config **/
    var minieditor = new MediumEditor('.minieditable', {
      toolbar: {
        buttons: [
          {
            name: 'bold',
            contentDefault: '<span class="fas fa-bold"></span>',
          },
          {
            name: 'italic',
            contentDefault: '<span class="fas fa-italic"></span>',
          },
          {
            name: 'underline',
            contentDefault: '<span class="fas fa-underline"></span>',
          },
          {
            name: 'strikethrough',
            contentDefault: '<span class="fas fa-strikethrough"></span>',
          },
          {
            name: 'anchor',
            contentDefault: '<span class="fas fa-link"></span>',
          },
          {
            name: 'unorderedlist',
            contentDefault: '<span class="fas fa-list-ul"></span>',
          },
          {
            name: 'orderedlist',
            contentDefault: '<span class="fas fa-list-ol"></span>',
          }
        ]
      },
      anchor: {
         /* These are the default options for anchor form,
            if nothing is passed this is what it used */
         customClassOption: null,
         customClassOptionText: 'Button',
         linkValidation: false,
         placeholderText: 'Paste or type a link',
         targetCheckbox: false,
         targetCheckboxText: 'Open in new window'
      }
    });
}
