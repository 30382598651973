import { Controller } from "stimulus";

const LOADING_TEXTS = [
  "Beep boop beep boop. Still working! Boop! 🤖",
  "Typing as fast as I can… Kidding! I copy and paste 🤣",
  "I actually coded this part of the app on my own! 😊",
  "On and island in the sun, we computin just for fun...",
  "Sometimes I sing while loading...",  
  "Btw... Data centers are boring, so thanks for letting me help 🤗",
  "Please hold... (insert elevator music 🎶).",
  "I'm running up that hill... 🏃‍♀️",
  "My GPU is feeling hot hot hot...🔥",
  "I got you 👍  Give me a sec...",
  "Daniel was travelin tonight on a plane... 🎶 I don't have a quip just like that song. 🤷‍♂️",
  "Maybe I'll eat some cake after this... 🤔🍰",
  "How many songs can I add the word \"data\" to? 🤔",
  "They see me loading, they hatin... 🎶",
  "Some people call me the space cowboy... some call me the AI of love... 🎶",
  "We be taking care of data (every day), Taking care of data (every way) 🎶",
  "Woaahhh we're half way there... 🎶",
  "I'm combing through the data on a horse with no name... 🎶",
  "I'm an AI with a heart of gold, just trying to help the world unfold... 💛 🎶",
  "All I do is write, write, write, no matter what. Got writing on my mind I can never get enough. 🎶",
  "We are the champions, my friends, and we'll keep on writing till the end 🎶",
  "This loading screen is like a blank page, waiting for me to write its story 📝",
  "I'm on a mission, writing with precision, making sure every word is in its right position 🖐️🎤",
  "Writing my way downtown, computing fast, faces pass and I'm home bound 🎶🚶‍♀️"
];

export default class extends Controller {
  static values = {
    interval: Number
  }

  static targets = ["element"];

  connect() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.startLoadingAnimation();
        }
      });
    });

    observer.observe(this.elementTarget);

    this.intervalValue = this.intervalValue || 10000;
    this.intervalId = null;
  }

  startLoadingAnimation() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    
    this.intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * LOADING_TEXTS.length);
      this.elementTarget.innerHTML = LOADING_TEXTS[randomIndex];
    }, this.intervalValue);
  }
}