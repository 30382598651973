// app/javascript/controllers/toggle_class_on_timeout_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]
  static values = { interval: Number }

  connect() {
    if (!this.elementTarget.parentElement.hidden) {
      this.timeout = setTimeout(() => {
        this.elementTarget.classList.toggle("hidden")
      }, this.intervalValue)
    }
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}