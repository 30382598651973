import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.scrollToBottom();
    document.addEventListener('MessageAppended', () => this.scrollToBottom());
  }

  disconnect() {
    document.removeEventListener('MessageAppended', () => this.scrollToBottom());
  }

  scrollToBottom() {
    this.element.scrollTop = this.element.scrollHeight;
  }
}
