import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    document.addEventListener("turbo:before-render", this.handleTurboBeforeRender)
  }

  disconnect() {
    this.removeClickListener()
    document.removeEventListener("turbo:before-render", this.handleTurboBeforeRender)
  }

  handleTurboBeforeRender() {
    this.removeClickListener()
  }

  closeOnClick() {
    this.addClickListener()
  }

  evaluateClick(event) {
    const target = this.elementTarget
    const defaultClass = "d-block"
    
    const element = document.getElementById(target.getAttribute('data-element-id'))
    const ignoredElements = target.hasAttribute('data-except-id') ? target.getAttribute('data-except-id').split(' ') : []
    const exitElement = target.hasAttribute('data-exit-id') ? document.getElementById(target.getAttribute('data-exit-id')) : null
    const addClass = target.getAttribute('data-close-add-class') || ""
    const removeClass = target.getAttribute('data-close-remove-class') || ""
    const isExit = event.composedPath().includes(exitElement)
    const withinBoundaries = event.composedPath().includes(element) && !isExit
    const isIgnored = ignoredElements.some(id => event.composedPath().includes(document.getElementById(id)))

    if (withinBoundaries || isIgnored) {
      // reset click listener if within boundaries or ignored
      //console.log ("click ignored")
      this.addClickListener()
    } else {
      if (addClass) this.addClass(element, addClass)
      if (removeClass) this.removeClass(element, removeClass)
    }
  }

  addClickListener() {
    this.boundEvaluateClick = event => this.evaluateClick(event)
    document.addEventListener("click", this.boundEvaluateClick, { capture: true, once: true })
  }

  addClass(el, cl) {
    el.classList.add(cl)
  }

  removeClass(el, cl) {
    el.classList.remove(cl)
  }
}