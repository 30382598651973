// app/javascript/controllers/load_conva_interface_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log("Loading Conva Interface")
    var container = document.getElementById('conva-interface-container');
    var url = this.data.get('url');

    fetch(url)
      .then(response => response.text())
      .then(html => {
        container.innerHTML = html;
      })
      .catch(error => {
        console.warn(error);
      });
  }
}