// app/javascript/controllers/sortable_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sortableItems"]

  connect() {
    this.setupSortable()
  }

  setupSortable() {
    const drop_indicator_color = '#684fca'
    const selected_item_bg_color = '#f5f6ff'
    const sortableItems = this.sortableItemsTarget;
    const sortableContainer = sortableItems.querySelector("ul");
  
    let draggedItem = null;
    let dropIndicator = document.createElement('div');
    dropIndicator.className = 'drop-indicator';
  
    dropIndicator.style = `background-color: ${drop_indicator_color}; height: 3px; width: 100%;`;
  
    const listItems = sortableContainer.querySelectorAll("li");

    // Initialize event listeners
    const initEventListeners = () => {
      const currentListItems = sortableContainer.querySelectorAll("li");
      currentListItems.forEach((listItem) => {
        listItem.setAttribute("draggable", "true"); // Set draggable attribute
    
        // Remove previous listeners before adding new ones
        listItem.removeEventListener("dragstart", onDragStart);
    
        listItem.addEventListener("dragstart", onDragStart);
      });
    
      sortableContainer.removeEventListener("dragover", onDragOver);
      sortableContainer.removeEventListener("drop", onDrop);
    
      sortableContainer.addEventListener("dragover", onDragOver);
      sortableContainer.addEventListener("drop", onDrop);
    };

    // Event listeners
    const onDragStart = (event) => {
      const listItem = event.target.closest('li');
      event.dataTransfer.setData("text/html", listItem.outerHTML);
      event.dataTransfer.setData("text/plain", listItem.dataset.sortableItemId);
      draggedItem = listItem;
      draggedItem.style = `background-color: ${selected_item_bg_color}; opacity: 0.7;`;
    };


    const onDragOver = (event) => {
      event.preventDefault();
      const target = event.target.closest("li");
      
      if (target) {
        const mouseY = event.clientY;
        const boundingRect = target.getBoundingClientRect();
        const targetOffsetY = boundingRect.top;
        const targetHeight = boundingRect.height;
        
        if (mouseY < (targetOffsetY + (targetHeight / 2))) {
          target.insertAdjacentElement("beforebegin", dropIndicator);
        } else {
          target.insertAdjacentElement("afterend", dropIndicator);
        }
      }
    };

    const onDrop = (event) => {
      event.preventDefault();
      const draggedItemId = event.dataTransfer.getData("text/plain");
      const target = event.target.closest("li");
    
      if (dropIndicator.parentNode) {
        const draggedItemHtml = event.dataTransfer.getData("text/html");
        const draggedItemElement = new DOMParser().parseFromString(draggedItemHtml, "text/html").body.firstChild;
    
        if (draggedItemElement && draggedItemElement instanceof Node) {
          sortableContainer.insertBefore(draggedItemElement, dropIndicator);
          draggedItem.remove();
          dropIndicator.remove();
          this.saveOrder();
    
          // Re-initialize event listeners
          initEventListeners();
        } else {
          dropIndicator.remove();
        }
      } else {
        dropIndicator.remove();
      }
    };

    // Initialize event listeners for the first time
    initEventListeners();
  }
    
  saveOrder() {
    const sortableItems = this.sortableItemsTarget.querySelectorAll("li");
    const order = Array.prototype.slice.call(sortableItems).map((item) => item.dataset.sortableItemId);

    const csrfToken = document.querySelector("meta[name='csrf-token']").content;

    fetch("/menu_items/update_order", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
        "Accept": "text/vnd.turbo-stream.html", // Request Turbo Stream response
      },
      body: JSON.stringify({ order }),
    });
  }
}