  /* 
  Options:

  Unique: Allow one class instance across specified elements [items], removes existing instances
  before applying class on current element. Also matches class by prefix. Must provide element 
  class or id via the item attribute.

  Item: class or id to check for existing instance of class.

  */

import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["element"]

  connect(){
    const targets = this.elementTargets
    targets.forEach((e) => {
      if (e.hasAttribute('data-onconnect') && e.getAttribute('data-onconnect') == "true") {
        this.addClass()
      }
    })
  }

  addClass(){
    const targets = this.elementTargets
    targets.forEach((e) => {

      const els = e.getAttribute('data-element-id').split(' ')
      const className = e.getAttribute('data-class')
      const unique = (e.hasAttribute('data-unique')) ? e.getAttribute('data-unique') : false ; 

      unique == "true" ? clearClass() : null ;
      addClass()

      function addClass(){
        els.forEach(el => {
          var el = document.querySelectorAll("#".concat(el))
          if(el){ 
            el.forEach((e) => {
              e.classList.add(className)
            })
          }
        })
      }
  
      function clearClass(){
        const elName = e.getAttribute('data-item')
        const prefix = e.getAttribute('data-prefix')
        const els = document.querySelectorAll(elName)

        els.forEach((el) => {
          if (prefix) {
            //console.log(el.classList)
            for (let i = el.classList.length - 1; i >= 0; i--) {
              const className = el.classList[i];
              if (className.startsWith(prefix)) {
                el.classList.remove(className);
              }
            }
          }else{
            el.classList.remove(className);
          }
        })

      }
    })
  }
}
