import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["element"]

  connect() {
      console.log("submit form loaded")
  }

  submitForm(){
    var form_id = this.elementTarget.getAttribute('data-form-id')
    var form = document.getElementById(form_id);
    setTimeout(() => {Rails.fire(form, "submit")}, 200);
  }

};