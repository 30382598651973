  /* 
  Options:

  Unique: Allow one class instance across specified elements [items], removes existing instances
  before applying class on current element. Also matches class by prefix. Must provide element 
  class or id via the item attribute.

  Item: class or id to check for existing instance of class.

  */

  import { Controller } from "stimulus"

  export default class extends Controller {
    static targets = ["element"]
  
    connect(){
      const targets = this.elementTargets
      targets.forEach((e) => {
        if (e.hasAttribute('data-onconnect') && e.getAttribute('data-onconnect') == "true") {
          this.removeClass()
        }
      })
    }
  
    removeClass(){
      const targets = this.elementTargets
      targets.forEach((e) => {
  
        const els = e.getAttribute('data-element-id').split(' ')
        const className = e.getAttribute('data-class')
  
        removeClass()
  
        function removeClass(){
          els.forEach(el => {
            var el = document.querySelectorAll("#".concat(el))
            if(el){ 
              el.forEach((e) => {
                e.classList.remove(className)
              })
            }
          })
        }
      })
    }
  }