$(document).on('turbo:load', function() {

  var reader_form = $('#readerOptionsForm')

  $('.form-trigger').on('click', function(){
    submit_form(reader_form)
  });

  $('.toggle-group').on('click', function(){
    let parent_container = $(this).closest('.share-icon')
    let indicator_classes = "option-true option-false"

    parent_container.toggleClass(indicator_classes);
    submit_form(reader_form)
  });

  function submit_form(form) {
    // setTimeout to prevent submission before toggle
    setTimeout(() => { reader_form.submit(); }, 200);
  };

});
