import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { destinationId: String, scopeId: String }

  connect() {
    this.mouseUpListener = this.copyText.bind(this);
    this.mouseDownListener = this.copyText.bind(this);
    this.mouseMoveListener = this.copyText.bind(this);
    const scopeElement = document.getElementById(this.scopeIdValue);
    scopeElement.addEventListener('mouseup', this.mouseUpListener);
    scopeElement.addEventListener('mousedown', this.mouseDownListener);
    scopeElement.addEventListener('mousemove', this.mouseMoveListener);
    this.scopeElement = scopeElement;
  }

  disconnect() {
    this.scopeElement.removeEventListener('mouseup', this.mouseUpListener);
    this.scopeElement.removeEventListener('mousedown', this.mouseDownListener);
    this.scopeElement.removeEventListener('mousemove', this.mouseMoveListener);
  }

  copyText() {
    const destination = document.getElementById(this.destinationIdValue);
    const selection = window.getSelection()
    const text = selection.toString()
    if (text) {
      destination.value = text
    }
  }
}