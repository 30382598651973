import Tagify from '@yaireo/tagify'
import "tempusdominus-bootstrap-4"
//import Coloris from "@melloware/coloris"
import 'vanilla-icon-picker/dist/themes/default.min.css'
import IconPicker from 'vanilla-icon-picker'


$(document).on('turbo:load', function() {
  tagify()
});

// Icon Picker - Compatibility issues with stimulus (doesn't load font-awesome icons) so adding here.
window.initIconPicker = function(){
  const button = document.querySelector('.btn-icon-picker');

  // Check if the icon picker has already been initialized
  if (button.dataset.iconPickerInitialized) {
    return;
  }

  console.log('initIconPicker')
  // Icon picker with `default` theme
  const iconPickerButton = new IconPicker(button, {
    theme: 'default',
    iconSource: ['FontAwesome Solid 6', 'FontAwesome Regular 6'],
    closeOnSelect: true,
    defaultValue: "fas fa-comment",
    i18n: {
      'text:title': 'Choose an icon',
      'input:placeholder': 'Search icons…'
    }
  });

  const iconElementButton = document.querySelector('.icon-selected-text');

  iconPickerButton.on('select', (icon) => {
    iconElementButton.innerHTML = `Icon selected name: <b>${icon.name}</b> & value: <b>${icon.value}</b>`;
  });

  // Mark the icon picker as initialized
  button.dataset.iconPickerInitialized = true;
}


const tagify = function() {
  // Tagify (frontend for tag fields)
  window.startTagify = function() {
    // The DOM element you wish to replace with Tagify
    var input = document.querySelector('.tagifyField');
    var tagify = new Tagify(input, {
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      enforceWhitelist : false,
      whitelist : [],
      dropdown : {
          classname     : "color-blue",
          enabled       : 0,              // show the dropdown immediately on focus
          maxItems      : 5,
          position      : "text",         // place the dropdown near the typed text
          closeOnSelect : false,          // keep the dropdown open after selecting a suggestion
          highlightFirst: true
      }
    });
  };
  startTagify()
}

// Affiliate Payment Form: Show and hide fields based on payment_type select.
$(document).on('turbo:load', function() {
 $('.affiliate_payment').hide();
  $('#ptype').change(function() {
    $('.affiliate_payment_box').hide();
    $('#affiliate_payment_' + $(this).val()).show();
  });
});

$(document).on('turbo:load', function() {
  var current_time = new Date();
  var start_of_day = moment.utc().startOf('day');
  var end_of_day = moment.utc().endOf('day');

  // Datepicker
  $.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
    debug: true,
    format: 'MMMM D YYYY, h:mm A',
    useCurrent: false,
    sideBySide: true,
    defaultDate: false,
    buttons: {
      showClose: true,
      showToday: false,
      showClear: false
    },
    icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar-alt',
      up: 'far fa-chevron-circle-up',
      down: 'far fa-chevron-circle-down',
      previous: 'far fa-caret-circle-left',
      next: 'far fa-caret-circle-right',
      today: 'far fa-calendar-check',
      clear: 'far fa-delete',
      close: 'fal fa-times-circle'
    } });

  // Date Range Fields
  $('#datetime-range-start').datetimepicker({
    maxDate: current_time,
  });

  $('#datetime-range-end').datetimepicker({
    maxDate: current_time,
  });

  $("#datetime-range-start").on("change.datetimepicker", function (e) {
    $('#datetime-range-end').datetimepicker('minDate', e.date);
  });

  $("#datetime-range-end").on("change.datetimepicker", function (e) {
    $('#datetime-range-start').datetimepicker('maxDate', e.date);
  });

});
// Show password helper (required format) on form field click
$(document).on('turbo:load', function() {
  $("#user_password").on('click', function(e){
    $('.password-helper').show();
  });
});

// Frontpage POTD email toggle
$(document).on('turbo:load', function() {
  let status = $('#potd_status').data('potd')
  if (status == true){
    $('#email_potd').bootstrapToggle('on')
  }else {
    $('#email_potd').bootstrapToggle('off')
  }
});

window.toggleShareLink = function(){
  $('.urlButton').on('click', function(){
    let shareUrl = $(this).attr("data-url")
    console.log(shareUrl)
    $('#shareableLink').val(shareUrl)
    $('.urlButton').removeClass('active')
    $(this).addClass('active')
  });
};

$(document).on('turbo:load', function() {
  toggleShareLink();
});
