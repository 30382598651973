import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Clipboard controller connected");
  }

  copy(event) {
    event.preventDefault();
    const button = event.currentTarget; // Get the button that triggered the event
    const textToCopy = button.dataset.clipboardText; // Access the clipboard text from the button

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.showSuccessMessage(button); // Pass button to showSuccessMessage
      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    } else {
      this.fallbackCopyTextToClipboard(textToCopy, button); // Pass button to fallbackCopyTextToClipboard
    }
  }

  fallbackCopyTextToClipboard(text, button) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    // Position the textarea off-screen
    textArea.style.position = 'fixed';
    textArea.style.top = '-1000px';
    textArea.style.left = '-1000px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      this.showSuccessMessage(button); // Pass button to showSuccessMessage
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  showSuccessMessage(button) {
    const originalContent = button.innerHTML;
    const successMessage = this.data.get("value") || "<span class='fas fa-check'></span>"; // Default message if "value" is not set
    button.innerHTML = successMessage;

    setTimeout(() => {
      button.innerHTML = originalContent;
    }, 2000);
  }
}