// image_search_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["result"]

  setImage(){
      let src = this.resultTarget.getAttribute("src");
      let description = this.resultTarget.hasAttribute("data-description") ? this.resultTarget.getAttribute("data-description") : "api image";
      let downloadLink = this.resultTarget.getAttribute("data-download-link");
      let photographer = this.resultTarget.getAttribute("data-photographer");
      let photographerUrl = this.resultTarget.getAttribute("data-photographer-url");
      let downloadLocation = this.resultTarget.getAttribute("data-download-location");

      document.getElementById('external_image_url').value = src
      document.getElementById('external_image_description').value = description
      document.getElementById('external_image_download_link').value = downloadLink
      document.getElementById('external_image_photographer').value = photographer
      document.getElementById('external_image_photographer_url').value = photographerUrl
      document.getElementById('external_image_download_location').value = downloadLocation
  
      // submit form
      document.getElementById('externalImageForm').submit()

      //or submit without triggering html response from turbo
      //document.getElementById('new_external_image').dispatchEvent(new CustomEvent('submit', { bubbles: true }))

      window.scrollTo(0, 0);
    };
}