import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["expandableField"]

  connect() {
    setTimeout(() => {this.adjustFieldHeight();}, 50);
    setTimeout(() => {this.adjustFieldHeight();}, 100);
    setTimeout(() => {this.adjustFieldHeight();}, 500);
    setTimeout(() => {this.adjustFieldHeight();}, 1000);
    setTimeout(() => {this.adjustFieldHeight();}, 2500);
    setTimeout(() => {this.adjustFieldHeight();}, 5000);
    setTimeout(() => {this.adjustFieldHeight();}, 10000);
  }

  adjustFieldHeight() {
    //console.log("adjusting field height")
    var text_area = this.expandableFieldTarget
    text_area.style.height = 0;
    var padding_top = parseInt(getComputedStyle(text_area, null).getPropertyValue('padding-top'))
    var padding_bottom = parseInt(getComputedStyle(text_area, null).getPropertyValue('padding-bottom'))
    var field_height = text_area.scrollHeight + 10
    var full_height = field_height - padding_top - padding_bottom;
    text_area.style.height = full_height + 'px';
  }
};