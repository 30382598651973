$(document).on('turbo:load', function() {

  $('.selectTestimonial').on('click', function(){
    testimonial = $(this).attr("id")
    change_avatar($(this))
    change_testimonial(testimonial)
  });

  function change_avatar(avatar) {
    $('.testimonial-thumb').removeClass("active-testimonial")
    $(avatar).addClass("active-testimonial")
  };

  function change_testimonial(testimonial) {
    let new_testimonial_card = '.testimonial-card'+'#'+ testimonial
    $('.testimonial-card').removeClass("active-testimonial")
    $(new_testimonial_card).addClass("active-testimonial")
  };

});
