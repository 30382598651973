import * as Clipboard from "vendor/clipboard.min.js"

$(document).on('turbo:load', function() {
    loadClipboard();
});

// Clipboards (uses clipboard.min.js)
window.loadClipboard = function() {
    var clipboard = new Clipboard('.btn-clipboard');

    clipboard.on('success', function(e) {
        console.log(e);
    });

    clipboard.on('error', function(e) {
        console.log(e);
    });

    changeClipboardButton();
};

function changeClipboardButton(){
    $('.copyemailButton').on("click", function(){
        var $this = $(this);
        $this.find( '.fa' ).removeClass('fa-copy').addClass('fa-check')
        $this.find( '.copyemailText' ).text('Email Copied!');
    });

    $('.copylinkButton').on("click", function(){
        var $this = $(this)
        $this.find( '.fa' ).removeClass('fa-copy').addClass('fa-check')
        $this.find( '.copylinkText' ).text('copied!');
    });
};