import { Controller } from "stimulus"

export default class extends Controller {

  destroy(event) {
    event.preventDefault()

    let data = new FormData()
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    console.log("data--------")
    Rails.ajax({
      url: "/contact_bulks",
      type: "DELETE",
      data: data
    })
  }
}