import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["element"]

  connect() {
    this.checkWordCount()
  }

  addClass(event) {
    this.checkWordCount()
  }

  checkWordCount() {
    const targets = this.elementTargets
    const parser = new DOMParser(); 
    targets.forEach((e) => {
      const wordCountElement = document.querySelector('.' + e.getAttribute('data-word-count-element-class'));
      const minWordCount = e.getAttribute('data-min-word-count-value');
      const className = e.getAttribute('data-class');
      const els = e.getAttribute('data-target-id').split(' ');
      const doc = parser.parseFromString(wordCountElement.innerHTML, 'text/html'); 
  
      // Strip the HTML tags
      let plainText = doc.body.textContent.replace(/<[^>]*>?/gm, '');
      let words = plainText.trim().split(/\s+/);
      let wordCount = words.length;
      
      if (wordCount >= parseInt(minWordCount)) {
        els.forEach((e) => {
          let el = document.getElementById(e)
          el.classList.remove(className);
        })
      } else {
        els.forEach((e) => {
          let el = document.getElementById(e)
          el.classList.add(className);
        })
      }
    })
  }
}