/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs';
Rails.start();
import { cable } from "@hotwired/turbo-rails"

import "core-js/stable"
import "regenerator-runtime/runtime"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// Jquery
import $ from "jquery";
import "jquery-ujs"
import "jquery-ui-dist/jquery-ui.js"
import "jquery-datetimepicker"

// Bootstrap
import "bootstrap"
import "bootstrap-toggle"
import "bootstrap-select"
import "bootstrap-fixes"
import "moment"
import "handlebars"

// Forms & Filters
import '@yaireo/tagify'
import "@client-side-validations/client-side-validations"
import "@melloware/coloris/dist/coloris.css"
import "filterrific"
import "forms"
import "charts"
import "clipboards"
import "mailgun_validator.js"
import "email_validation.js"
import "vendor/date.js"
import "posts.js"
import "misc.js"
import "navigation.js"
import "reader_options.js"
import "static.js"


//checkout
import "price.js"

import "surveys.js"
import "survey_questions.js"
import { moment } from "vendor/moment.js"
import "vanilla-cookieconsent"


const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)

application.load(definitionsFromContext(context))

// temp fix for various components until I can find a more "webpack" way of doing this
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = $;
window.jQuery = $;
window.$ = $;

