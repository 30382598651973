import { Controller } from "stimulus";

export default class extends Controller {
  
  replace() {
    const sourceContent = this.data.get("sourceText");
    const selectedText = window.getSelection().toString();

    if (selectedText) {
      const newText = document.createTextNode(sourceContent);
      const range = window.getSelection().getRangeAt(0);
      range.deleteContents();
      range.insertNode(newText);
    }
  }
}