import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {}

  selectField(){
    let element_id = this.elementTarget.getAttribute('data-field-id')
    document.getElementById(element_id).select();
  }
};


