import { Controller } from 'stimulus';
import MediumEditor from 'medium-editor';

export default class extends Controller {
  static targets = ['element']

  connect() {
    // do something on connect
  }

  copyValues() {
    const targets = this.elementTargets
    targets.forEach((e) => {
      var container_id = e.getAttribute('data-container-id');
      var container = document.querySelector('#' + container_id);
      const formName = e.getAttribute('data-form-name');
      const dataElements = container.querySelectorAll('[data_target]');
      // Loop through each data target
      dataElements.forEach((de) => {
        const targetName = de.getAttribute('data_target');
        const element_id = `${formName}_${targetName}`
        const correspondingFormElement = document.getElementById(element_id);
        console.log(targetName)
        
        // set the body field if mediumeditor.js enabled
        if (targetName == 'editable') {
          var editorField = document.querySelector('.editable')
          var keyupEvent = new KeyboardEvent('keyup', {
            key: 'Enter' // Specify the desired key you want to simulate, such as 'Enter'
          });
          console.log(editorField)
          var editor = MediumEditor.getEditorFromElement(editorField);
          if (editor) {
            editor.elements[0].innerHTML = de.innerHTML.trim()
            editor.trigger('editableInput', null, editor.elements[0]);
            document.dispatchEvent(keyupEvent); // resumes autosave if enabled
          } else {
            console.log('No Medium Editor instance found');
          }
        }else{
          correspondingFormElement.value = de.innerHTML.trim().replace(/<[^>]*>?/gm, '');
        }

      });
    });
  }
}