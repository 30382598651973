import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["data"]
  connect(){
    // console.log("Hello World!")
  }
  updateValue(){
    const targets = this.dataTargets
    targets.forEach((e) => {
      var newValue = e.getAttribute('data-value')
      var formField = e.getAttribute('data-id')
      document.getElementById(formField).value = newValue;
    })
  }
};