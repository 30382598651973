import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ["element"]

  connect() {}

  confirm() {
    let confirmText = this.elementTarget.getAttribute('data-confirm-text')
    let confirmField = document.getElementById(this.elementTarget.getAttribute('data-confirm-field'))
    let confirmButton = document.getElementById(this.elementTarget.getAttribute('data-confirm-button'))
    
    confirmField.value == confirmText ? confirmButton.disabled = false : confirmButton.disabled = true;
  }
}