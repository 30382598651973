import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "inputRecipient", "textRecipient"]
  connect(){
    copyText()
  }

  copyText(){
    const copyTarget = this.sourceTarget.getAttribute('data-id')
    const sel = document.getElementById(copyTarget).selectedOptions[0]
    const newValue = sel.text
    this.inputRecipientTargets.forEach(recipient => document.getElementById(recipient.getAttribute('id')).value = newValue)
    this.textRecipientTargets.forEach(recipient => document.getElementById(recipient.getAttribute('id')).innerHTML = newValue)
  }
};