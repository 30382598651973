import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["config"]

  connect() {
    setTimeout(() => {this.preloaded();}, 500);
  }

  preloaded(){
    var loader_id = this.configTarget.getAttribute('data-loader-id')
    var content_id = this.configTarget.getAttribute('data-content-id')
    var content = document.getElementById(content_id)
    var loader = document.getElementById(loader_id)

    this.fadeOutLoader(loader)
    this.fadeInContent(content, "block")
  }

  fadeOutLoader(el){
    el.style.opacity = 1;
    (function fade() {
      if ((el.style.opacity -= .1) < 0) {
        el.style.display = "none";
      } else {
        requestAnimationFrame(fade);
      }
    })();
    console.log("preloader hidden")
  }

  fadeInContent(el, display){
    el.style.opacity = 0;
    el.style.display = display || "block";
    (function fade() {
      var val = parseFloat(el.style.opacity);
      if (!((val += .1) > 1)) {
        el.style.opacity = val;
        requestAnimationFrame(fade);
      }
    })();
    console.log("preloaded element displayed")
  };
};