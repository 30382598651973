import "jquery.maskedinput/src/jquery.maskedinput"

// Payment Plan Selection
$('#quarterly-button').on('click', function(event) {
             $('.ann-price').hide();
             $('.two-year-price').hide();
             $('.quarterly-price').show();
             $("#annual-button").removeClass("btn-white");
             $("#annual-button").addClass("btn-green");
             $("#two-year-button").removeClass("btn-white");
             $("#two-year-button").addClass("btn-green");
             $("#quarterly-button").removeClass("btn-green");
             $("#quarterly-button").addClass("btn-white");
        });

$('#annual-button').on('click', function(event) {
             $('.two-year-price').hide();
             $('.quarterly-price').hide();
             $("#two-year-button").removeClass("btn-white");
             $("#two-year-button").addClass("btn-green");
             $("#quarterly-button").removeClass("btn-white");
             $("#quarterly-button").addClass("btn-green");
             $("#annual-button").removeClass("btn-green");
             $("#annual-button").addClass("btn-white");
             $('.ann-price').show();
        });
$('#two-year-button').on('click', function(event) {
             $('.ann-price').hide();
             $('.quarterly-price').hide();
             $('.two-year-price').show();
             $("#quarterly-button").removeClass("btn-white");
             $("#quarterly-button").addClass("btn-green");
             $("#annual-button").removeClass("btn-white");
             $("#annual-button").addClass("btn-green");
             $("#two-year-button").removeClass("btn-green");
             $("#two-year-button").addClass("btn-white");

        });

// Contact Coach
$(document).on('turbo:load', function() {
  $('.contact-coach-button').on('click', function() {
    $('#coach_message_entry_id').val($(this).data('entry-id'));
    $('#coach_message_message').val('');
  })
});

$(document).on('turbo:load', function() {
  $('#trigger-image-search').on('click', function() { $('#imageForm').submit(); });
  $('#tmpPasswordModal').modal('show');
});

$(document).on('turbo:load', function() {
  $('#phone').mask('999-999-9999');
});

