import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]
  connect(){

  }
  copyValue(){
    const targets = this.elementTargets
    targets.forEach((e) => {
      const copyTarget = e.getAttribute('data-copy-from');
      const pasteTargetDataAttribute = e.getAttribute('data-paste-target-attribute');
      var element = document.getElementById(copyTarget);
      if (!element) {
        var elements = document.getElementsByName(copyTarget);
        if (elements.length > 0) {
          element = elements[0];
        }
      }
      const newValue = element ? element.value : null;
      const pasteTarget = e.getAttribute('data-copy-to');
      if (pasteTargetDataAttribute) {
        document.getElementById(pasteTarget).setAttribute(pasteTargetDataAttribute, newValue);
      } else {
        document.getElementById(pasteTarget).value = newValue;
      }
    }
  )}
};