import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "element" ]

  connect() {
    this.updateClass()
  }

  updateClass() {
    console.log("Select clicked")
    const targets = this.elementTargets
    targets.forEach((e) => {
      const els = e.getAttribute('data-element-id').split(' ')
      els.forEach((el) => {
        const selectFieldId = e.getAttribute("data-select-field")
        const selectedValues = e.getAttribute("data-select-value").split(" ")
        const class_when_selected = e.getAttribute("data-class-when-selected")
        const selectField = document.getElementById(selectFieldId);
        const targetElement = document.getElementById(el);
        //console.log(selectFieldId)

        if (selectedValues.includes(selectField.value.toLowerCase() )) {
          targetElement.classList.add(class_when_selected)
        } else {
          targetElement.classList.remove(class_when_selected)
        }
      })
    })
  }
}