import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    this.toggleClass()
  }

  toggleClass(){
    // var element = document.getElementById(element_id)
    // currently assumes scroll positions are on y axis
    var targetElement = this.elementTarget.getAttribute('data-element-id')
    var element = document.getElementById(targetElement)

    if (element != null) {
      var classToToggle = this.elementTarget.getAttribute('data-class')
      var minPos = Number(this.elementTarget.getAttribute('data-min-scroll-position'))
      var maxPos = Number(this.elementTarget.getAttribute('data-max-scroll-position'))
      var currentPos = window.scrollY + 1
      
      if(currentPos + 1 > minPos && currentPos + 1 < maxPos){
          element.classList.add(classToToggle);
      }else{
          element.classList.remove(classToToggle);
      }
    }else{
      // console.log("No element found with id: " + targetElement)
    }
  }

};