import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["element"];

  // Bind the function and store it in a variable
  boundAddClass = this.addClass.bind(this);

  connect() {
    document.addEventListener("mouseup", this.boundAddClass);
    document.addEventListener("selectionchange", this.boundAddClass);
    document.addEventListener("removeListeners", this.removeEventListeners.bind(this));
  }

  disconnect() {
    document.removeEventListener("mouseup", this.boundAddClass);
    document.removeEventListener("selectionchange", this.boundAddClass);
    document.removeEventListener("removeListeners", this.removeEventListeners.bind(this));
  }
  
  addClass() {
    const targets = this.elementTargets;
    targets.forEach((e) => {
      const scopeId = e.getAttribute("data-scope-id");
      const scopeElement = document.getElementById(scopeId);
      const elementName = e.getAttribute("data-element-id");
      const elementClass = e.getAttribute("data-element-class");
      const className = e.getAttribute("data-class");
      const selectedText = window.getSelection();
      const persist = e.getAttribute("data-persist") === "true" ? true : false;
  
      const selectionIsWithinScope = scopeElement.contains(selectedText.anchorNode);
  
      if (selectedText.toString().length === 0 && persist != true) {
        if (elementName) {
          const contentElement = document.getElementById(elementName);
          contentElement.classList.remove(className);
        } else if (elementClass) {
          const contentElements = document.getElementsByClassName(elementClass);
          Array.from(contentElements).forEach((el) => el.classList.remove(className));
        }
      } else if (selectedText.toString().trim().length > 0 && selectionIsWithinScope) {
        if (elementName) {
          const contentElement = document.getElementById(elementName);
          contentElement.classList.add(className);
        } else if (elementClass) {
          const contentElements = document.getElementsByClassName(elementClass);
          Array.from(contentElements).forEach((el) => el.classList.add(className));
        }
      }
    });
  }

  removeEventListeners() {
    document.removeEventListener("mouseup", this.boundAddClass);
    document.removeEventListener("selectionchange", this.boundAddClass);
  }
  
}