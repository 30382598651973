$(document).on('turbo:load', function() {
  $('body').on('click', '.survey_survey_questions_survey_possible_answers_free_text label input.boolean', function() {
    if( $(this).is(':checked')) {
      $(this).parent().parent().parent().next().next().removeClass('hidden').show();
      $(this).parent().parent().parent().next().hide().addClass('hidden');
    } else {
      $(this).parent().parent().parent().next().next().hide();
      $(this).parent().parent().parent().next().removeClass('hidden').show();
    }
  });
  $(".surveys").on('change', '.survey-question-container .select', function() {
    var selectVal = $(this).find(":selected").val()
    if (selectVal == "radio" || selectVal =='checkbox' || selectVal == 'select') {
      $(this).parent().parent().children('.add-answer').attr('style', 'display: block;');
    } else {
      $(this).parent().parent().children('.add-answer').attr('style', 'display: none;');
    }
  });
  $('.surveys').on('change', '.exit-after .boolean', function() {
      $(this).parent().next('.exit-url').attr('style', 'display: block;');
  });
});




