import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]
  // this needs to be broken out so that it supports mutiple elements
  remove(event) {
    console.log
    const targets = this.elementTargets
    targets.forEach((e) => {
      event.preventDefault();
      const customEvent = new CustomEvent("removeListeners");
      document.dispatchEvent(customEvent);
    })
  }
  
}