import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["data"]
  connect(){
    // console.log("Hello World!")
  }
  updateContent(){
    var newContent = this.dataTarget.getAttribute('data-value')
    var contentID = this.dataTarget.getAttribute('data-id')
    document.getElementById(contentID).innerHTML = newContent;
  }
};