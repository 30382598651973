import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    this.selectElement()
  }

  selectElement(){
    var element_id = this.elementTarget.getAttribute('data-element-id')
    var element = document.getElementById(element_id);
    this.clearSelected(element)
    this.addSelected(element)
  }

  addSelected(el){
    el.classList.add( "selected" );
  }

  clearSelected(el){
    el.parentElement.querySelectorAll( ".selected" ).forEach( e =>
      e.classList.remove( "selected" ) );
  }
};