import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["element"]

	connect() {
		this.toggleClass();
	}

	toggleClass(){
		var elements = this.elementTarget.getAttribute('data-element-id').replace(/\s+/g, '').split(',')
		var classToToggle = this.elementTarget.getAttribute('data-class')
		console.log(elements)
		elements.forEach(element => {
			var target = document.getElementById(element)
			if (target){
				target.classList.toggle(classToToggle)
			}
		})
	}
}
