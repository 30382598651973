import * as Highcharts from "highcharts"

// HighCharts
$(document).on('turbo:load', function() {
    Highcharts.setOptions({
      lang: {
          printChart: 'Aaaa',
          thousandsSep: ','
      }
  });
});
  
$(document).on('turbo:load', function() {
    function coordinates(element) {
        element = $(element);
        var top = element.position().top;
        var left = element.position().left;
        $('.top-coord').val(top)
        $('.left-coord').val(left)
    }

    $('.draggable').draggable({
        start: function() {
            coordinates(this);
        },
        stop: function() {
            coordinates(this);
        }
    });
});



