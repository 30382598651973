import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["element"]

  connect() {
    var onconnect = this.elementTarget.getAttribute('data-onconnect')
    if (onconnect === 'true'){
     this.toggleClass()
    }
  }

	toggleClass(){
		const targets = this.elementTargets
		targets.forEach((e) => {
			const is_unique = (e.hasAttribute('data-unique') && e.getAttribute('data-unique')) ? true : false ; 
			const els = e.getAttribute('data-element-id').split(' ')
			const cls = e.getAttribute('data-class').split(' ')
			//console.log("toggle class")
			if (is_unique && e.hasAttribute('data-item')){
				//console.log("set as unique")
				const itemName = e.getAttribute('data-unique') 
			}
			els.forEach(el => {
				var el = document.getElementById(el)
				if (el){
					cls.map(cl => el.classList.toggle(cl))
				}
			})
		})
	}
}
