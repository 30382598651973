import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["element"]

	connect() {
	}

	toggleClass(){
		var classToToggle = this.elementTarget.getAttribute('data-body-class')
		document.body.classList.toggle(classToToggle)
	}

}
