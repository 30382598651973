import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["element"]

  connect() {
    var onconnect = this.elementTarget.getAttribute('data-onconnect')
    if (onconnect === 'true'){
     this.scrollTo() 
    }
  }

  scrollTo(){
    var behavior = this.elementTarget.hasAttribute('data-behavior') ? this.elementTarget.getAttribute('data-behavior') : 'auto' ;
    var element_id = this.elementTarget.getAttribute('data-element-id')
    var element = document.getElementById(element_id);
    var yOffset = -90; // account for top navbar
    var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: behavior});
  }
};