import { Controller } from "stimulus"

// Use the following "on" events to ensure complete coverage:
//click->disable-if-blank#validate 
//change->disable-if-blank#validate 
//focusout->disable-if-blank#validate

export default class extends Controller {
  static targets = ["element"]
  connect(){
    // console.log("Hello World!")
  }
  validate(){
    const targets = this.elementTargets
    targets.forEach((e) => {
      // toggle visability of disabled / enabled buttons - we need to use multiple elements vs just disabling or enabling a single button
      // element because of additional stimulus events on the enabled element.
      const field = document.getElementById(e.getAttribute('data-disable-field'))
      const container = document.getElementById(e.getAttribute('data-container'))
      //console.log("validating...")
      if(field.value == ""){
        //console.log("disabling button")
        container.classList.add("d-none")
      }else{
        //console.log("enabling button")
        container.classList.remove("d-none")
      }

    })
  }
};