// JavaScript Document

$(document).ready( function() {
    $("#total").val("?")
    $("#amount").val("Articles Written Per Week");
    $("#amountb").val("Select Your Rate Per Article");
    $("#amountc").val("Choose a Timeframe");

    $( function() {
        var iValue = 5;
        var iValue2 = 3;
        var iValue3 = 1;
        var p = {

            0: "1 Article Per Week",
            1: "2 Articles Per Week",
            2: "3 Articles Per Week",
            3: "4 Articles Per Week",
            4: "5 Articles Per Week",
            5: "6 Articles Per Week",
            6: "7 Articles Per Week",
            7: "8 Articles Per Week",
            8: "9 Articles Per Week",
            9: "10 Articles Per Week",
            10: "11 Articles Per Week",
            11: "12 Articles Per Week",
            12: "13 Articles Per Week",
            13: "14 Articles Per Week",
            14: "15 Articles Per Week",

        };
        var t = {

            0: "1",
            1: "2",
            2: "3",
            3: "4",
            4: "5",
            5: "6",
            6: "7",
            7: "8",
            8: "9",
            9: "10",
            10: "11",
            11: "12",
            12: "13",
            13: "14",
            14: "15",

        }
        var b = {

            0: "$25 Per Article",
            1: "$50 Per Article",
            2: "$75 Per Article",
            3: "$100 Per Article",
            4: "$150 Per Article",
            5: "$200 Per Article",
            6: "$500 Per Article",
        };
        var d = {

            0: "25",
            1: "50",
            2: "75",
            3: "100",
            4: "150",
            5: "200",
            6: "500",
        }
        var a = {

            0: "1 Month",
            1: "3 Months",
            2: "6 Months",
            3: "1 Year",
        };
        var c = {

            0: "4",
            1: "13",
            2: "26",
            3: "52",
        };

        $("#slider").slider({

            value: iValue,
            min: 0,
            max: 14,
            step: 1,
            slide: function(event, ui) {

                $("#price").val(t[ui.value]);
                $("#amount").val(p[ui.value]);
                var aaa = $("#price").val();
                var bbb = $("#priceb").val();
                var ccc = $("#pricec").val();

                $("#total").val("$ " +aaa * +bbb * +ccc +".00");
                $("#total").digits();
            },
            create: function (event, ui) {
                $("#price").val("5");
                $("#amount").val("4 Articles Per Week");
                var aaa = '4'
                var bbb = '100'
                var ccc ='13'

                $("#total").val("$ " +aaa * +bbb * +ccc +".00");
                $("#total")
            }
        });
        $("#sliderb").slider({
            value: iValue2,
            min: 0,
            max: 6,
            step: 1,
            slide: function(event, ui) {
                $("#priceb").val(d[ui.value]);
                $("#amountb").val(b[ui.value]);
                var aaa = $("#price").val();
                var bbb = $("#priceb").val();
                var ccc = $("#pricec").val();
  
                $("#total").val("$ " +aaa * +bbb * +ccc +".00");
                $("#total").digits();
            },
            create: function (event, ui) {
                $("#priceb").val("100");
                $("#amountb").val("$100 Per Article");
                var aaa = '4'
                var bbb = '100'
                var ccc = '13'

                $("#total").val("$ " +aaa * +bbb * +ccc +".00");
                $("#total").digits();
            }
        });
        $("#sliderc").slider({
            value: iValue3,
            min: 0,
            max: 3,
            step: 1,
            slide: function(event, ui) {
                $("#pricec").val(c[ui.value]);
                $("#amountc").val(a[ui.value]);
                var aaa = $("#price").val();
                var bbb = $("#priceb").val();
                var ccc = $("#pricec").val();
                var ddd = $("#amountc").val();
   
                $("#total").val("$ " +aaa * +bbb * +ccc +".00");
                $("#total").digits();
                $("#period").html(ddd);
            },
            create: function (event, ui) {
                $("#priceb").val("13");
                $("#amountc").val("3 Months");
                var aaa = '4'
                var bbb = '100'
                var ccc = '13'
                var ddd = $("#amountc").val();

                $("#total").val("$ " +aaa * +bbb * +ccc +".00");
                $("#total").digits();
                $("#period").html(ddd);
                }
        });
        $("#price").val($("#slider").slider("value"));
        $("#priceb").val($("#slider").slider("value"));
        $("#pricec").val($("#slider").slider("value"));

        $("#price").val("4");
        $("#priceb").val("100");
        $("#pricec").val("13");

    }
    );

});
