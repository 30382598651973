import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    console.log('Turbo Frame Updater connected.')
  }

  updateTurboFrame(event) {
    event.preventDefault()

    const url = this.data.get('url')
    const frameId = this.data.get('frameId')

    fetch(url)
      .then(response => response.text())
      .then(html => {
        const turboFrameElement = document.getElementById(frameId)
        turboFrameElement.innerHTML = html
      })
  }
}