import Rails from '@rails/ujs';

// document ready
$(function() {
  // capture all enter and do nothing
  $('#registration_email, #user_message_email').keypress(function(e) {
    console.log("validation email field")
    if(e.which == 13) {
      $('#registration_email, #user_message_email').trigger('focusout');
      return false;
    }
  });
  // capture clicks on validate and do nothing
  $("#validate_submit").click(function() {
    return false;
  });

  // attach jquery plugin to validate address
  $('#registration_email, #user_message_email').mailgun_validator({
    api_key: 'pubkey-4f6a81e3f4324a9de334621471ef527d', // replace this with your Mailgun public API key
    in_progress: validation_in_progress,
    success: validation_success,
    error: validation_error,
  });
});
// while the lookup is performing
function validation_in_progress() {
  console.log("validating email with Mailgun")
  $('#email_status').html("");
  $("input[type=submit], button[type=submit]").attr('disabled','disabled');
}
// if email successfully validated
function validation_success(data) {
  var valid = data['is_valid']
  var dym = data['did_you_mean']
  var form = document.getElementById('formEmailSubmit');
  $('#email_status').html(get_suggestion_str(data['is_valid'], data['did_you_mean']));

  if(valid == true) {
    $("input[type=submit], button[type=submit]").removeAttr('disabled');
  }

  if (valid == true && form !== null){
    Rails.fire(form, 'submit'); 
  }
}
// if email is invalid
function validation_error(error_message) {
  $("input[type=submit], button[type=submit]").attr('disabled','disabled');
  $('#email_status').html('<div class="email-error">' + error_message + '</div>');

}
// suggest a valid email
function get_suggestion_str(is_valid, alternate) {
  if (is_valid) {
    var result = '';
    if (alternate) {
      result += '<div class="email-error">Did you mean <em>' + alternate + '</em>?</div>';
    }
    return result
  } else if (alternate) {
    return '<div class="email-error">Did you mean <em>' +  alternate + '</em>?</div>';
  } else {
    return '<div class="email-error"><span class="fa fa-warning register-icon"></span>Email Address is invalid.</div>';
  }
}