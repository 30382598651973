import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  disableEnter(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }
};